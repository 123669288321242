import React from "react"
import { FormattedMessage } from 'react-intl'

import Layout from "../components/layout"
import SEO from "../components/seo"

const ContactPage = ({ pathContext: { locale } }) => (
    <Layout locale={locale}>
        <SEO title="Ota yhteyttä"/>
        <div className={"container"}>
            <div className={"content"}>
                <div className={"title"}>
                    <h1><FormattedMessage id="Contact" /></h1>
                </div>

                <div className={"row"}>
                    <div className={"col-7"}>
                        <div className={"contact-items"}>
                            <p><FormattedMessage id="ContactDetails" /></p>
                            <h2>info@reko.ai</h2>
                        </div>
                    </div>

                    <div className={"col-5"}>
                        
                    </div>
                </div>
            </div>
        </div>
    </Layout>
)

export default ContactPage
